<template>
<div id="dashboard">
  <transition name="fade">
    <CommentModal v-if="showCommentModal" :post="selectedPost" @close="toggleCommentModal()"></CommentModal>
  </transition>
  <section>
    <!--  <div class="zcol1">
      <div class="profile">
        <h5>{{ userProfile.name }}</h5>
        <p>{{ userProfile.title }}</p>

        <div class="create-post">
          <p>Create incident reminder</p>
          <form @submit.prevent>
            <textarea v-model.trim="post.content" placeholder="short description"></textarea>
            <label for="priority">Select priority:</label>
            <select name="priority" id="priority" v-model.trim="post.priority">
              <option value="p1">30 min. from now - 30 min. reminder</option>
              <option value="p2">60 min. from now - 60 min. reminder</option>
              <option value="p3">90 min. from now - 45 min. reminder</option>
              <option value="p4">120 min. from now - 60 min. reminder</option>
            </select>

            <label for="scope">Select scope:</label>
            <select name="scope" id="scope" v-model.trim="post.scope">
              <option value="Local">Local</option>
              <option value="Regional">Regional</option>
              <option value="National">National</option>
              <option value="Global">Global</option>
            </select>

            <button @click="createPost()" :disabled="post.content === ''" class="button">
              Submit
            </button>
          </form>
        </div>
      </div> 
    </div> -->
    <div class="col2">
      <div v-if="posts.length">
        <div v-for="post in posts" :key="post.id" class="post" :class="[

getWarningCSSRef(post.id) ? 'remainder-card--warning' : '',

getExpireCSSRef(post.id) ? 'remainder-card--expired' : '',

]">

          <p><strong>Description:</strong> {{ post.content }}</p>
          <p>
            <strong>Prority:</strong> {{ post.priority }} |
            <strong>Scope:</strong> {{ post.scope }}
          </p>
          <!--    <span>{{ post.createdOn }}</span> -->

          <Counter :year="parseYYYY(post.countdown)" :month="parseMO(post.countdown)" :date="parseDD(post.countdown)" :authorEmail="post.userEmail" :authorAuthId="post.userId" :authorName="post.userName" :hour="parseHH(post.countdown)" :minute="parseMM(post.countdown)" :second="0" :millisecond="0" :warningTimout="post.warningTimout" @show-warning="setWarning($event, post.id)" @show-expired="setExpired($event, post.id)" />
          <p><strong>Author:</strong> {{ post.userName }}</p>
          <ul class="dashboard-links">
            <li>
              <a @click.prevent="toggleCommentModal(post)">Add comment ({{ post.comments }})</a>
            </li>
            <!--   <li><a @click="likePost(post.id, post.likes)">likes {{ post.likes }}</a></li> -->
            <li><a @click.prevent="viewPost(post)">View comments</a></li>
            <!--   <li><a @click="viewPost(post)">Reset</a></li>-->
            <li><a @click.prevent="deletePost(post)">Delete reminder</a></li>
          </ul>
        </div>
      </div>
      <div v-else>
        <p class="no-results">There are currently no incident reminders</p>
      </div>
    </div>
  </section>

  <!-- full post modal -->
  <transition name="fade">
    <div v-if="showPostModal" class="p-modal">
      <div class="p-container">
        <a @click="closePostModal()" class="close">close</a>
        <div class="post">
          <h5>{{ fullPost.userName }}</h5>
          <!--   <span>{{ fullPost.createdOn   }}</span> -->
          <p>{{ fullPost.content }}</p>
          <ul>
            <li>
              <a>comments {{ fullPost.comments }}</a>
            </li>
            <!--     <li><a>likes {{ fullPost.likes }}</a></li> -->
          </ul>
        </div>
        <div v-show="postComments.length" class="comments">
          <div v-for="comment in postComments" :key="comment.id" class="comment">
            <p>{{ comment.userName }}</p>
            <!--   <span>{{ comment.createdOn  }}</span> -->
            <p>{{ comment.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</div>
</template>

<script>
import {
  commentsCollection,
  postsCollection
} from "@/config/firebase";
import {
  mapState
} from "vuex";
import moment from "moment";
import CommentModal from "@/components/CommentModal";
import Counter from "@/components/Counter";

export default {
  name: "Dashboard",
  components: {
    CommentModal,
    Counter,
  },
  data() {
    return {
      post: {
        content: "",
        priority: "",
        countdown: "",
        scope: "",
      },
      showCommentModal: false,
      selectedPost: {},
      showPostModal: false,
      fullPost: {},
      postComments: [],
      showWarningCSS: {},
      showExpiredCSS: {},
    };
  },
  created() {
    this.$store.dispatch("loadPosts");
  },
  computed: {
    ...mapState(["userProfile", "posts"]),
  },
  methods: {
    getWarningCSSRef(lookup) {
      // console.log('getWarningExpireCSSRef', lookup)
      return this.showWarningCSS[lookup] || false;
    },
    getExpireCSSRef(lookup) {
      // console.log('getWarningExpireCSSRef', lookup)
      return this.showExpiredCSS[lookup] || false;
    },
    setWarning(e, i) {
      // console.log('got event show warning', e)
      this.showWarningCSS = {
        ...this.showWarningCSS,
        [i]: e,
      };
    },
    setExpired(e, i) {
      // console.log('got event show Expired', i)
      // { ...state, visibilityFilter: action.filter }
      this.showExpiredCSS = {
        ...this.showExpiredCSS,
        [i]: e,
      };
    },
    parseYYYY(val) {
      return new Date(val).getFullYear();
    },
    parseMO(val) {
      return new Date(val).getMonth();
    },
    parseDD(val) {
      return new Date(val).getDate();
    },
    parseHH(val) {
      return new Date(val).getHours();
    },
    parseMM(val) {
      return new Date(val).getMinutes();
    },
    getProperDateByPriority(priority) {
      // console.log('priority', priority)
      const countdownPriority = {
        p1: moment().add(30, "m"),
        p2: moment().add(60, "m"),
        p3: moment().add(90, "m"),
        p4: moment().add(120, "m"),
        default: moment().add(30, "m"),
      };
      return countdownPriority[priority] || countdownPriority["default"];
    },
    getWarningByPriority(priority) {
      // console.log('priority', priority)
      const warningTimeoutPriority = {
        p1: 15,
        p2: 30,
        p3: 45,
        p4: 60,
        default: 15,
      };
      return (
        warningTimeoutPriority[priority] || warningTimeoutPriority["default"]
      );
    },
    createPost() {
      //  console.log('calcCountDown', this.getProperDateByPriority(this.post.priority))
      const cdown = this.getProperDateByPriority(this.post.priority);
      this.$store.dispatch("createPost", {
        content: this.post.content,
        priority: this.post.priority,
        warningTimout: this.getWarningByPriority(this.post.priority),
        scope: this.post.scope,
        countdown: moment(cdown).format("llll"),
      });
      this.post.content = "";
      this.post.priority = "";
      this.post.scope = "";
    },
    toggleCommentModal(post) {
      this.showCommentModal = !this.showCommentModal;

      // if opening modal set selectedPost, else clear
      if (this.showCommentModal) {
        this.selectedPost = post;
      } else {
        this.selectedPost = {};
      }
    },
    likePost(id, likesCount) {
      this.$store.dispatch("likePost", {
        id,
        likesCount,
      });
    },
    async viewPost(post) {
      const docs = await commentsCollection
        .where("postId", "==", post.id)
        .get();

      docs.forEach((doc) => {
        let comment = doc.data();
        comment.id = doc.id;
        this.postComments.push(comment);
      });

      this.fullPost = post;
      this.showPostModal = true;
    },
    closePostModal() {
      this.postComments = [];
      this.showPostModal = false;
    },
    async deletePost(post) {
      if (
        window.confirm(
          `Ok to delete this reminder?`
        )
      ) {
        const docs = await postsCollection.doc(post.id).delete();
      } else {
        console.info("user canceled request to delete...");
      }
    },
  },
};
</script>

<style lang="scss">
.post {
  border: 1px solid gainsboro !important;
  background: rgba(0, 255, 0, 0.2) !important;
}

.comment {
  text-align: left;
}

.post.remainder-card--warning {
  background: rgba(255, 255, 0, 0.2) !important;
}

.post.remainder-card--expired {
  background: rgba(255, 0, 0, 0.2) !important;
}

.dashboard-links {

  $a-tags: 'a, a:active, a:hover, a:visited';
  $a-tags-hover: 'a:active, a:hover';

  #{$a-tags} {
    color: blue;
    text-decoration: underline;
  }

  #{$a-tags-hover} {
    color: gray;
  }

}
</style>
