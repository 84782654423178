import { createStore } from 'vuex'


import * as fb from '@/config/firebase'
import router from '../router/index'


export default createStore({

  state: {
    userProfile: {},
    posts: [],
    fbUID: null,
    hasMessaging: false
  },
  getters: {
      fbUID: state => {
        return state.fbUID;
      }

  },
  mutations: {
    setUserProfile(state, val) {
      // console.log ('user profile...', val)
      state.userProfile = val
    },
    setHasMessaging(state, val) {
      state.hasMessaging = val
    },
    setPerformingRequest(state, val) {
      state.performingRequest = val
    },
    setPosts(state, val) {
      state.posts = val
    },
    setFbUID(state, val) {
      state.fbUID = val
    }
  },
  actions: {
    async login({ dispatch }, form) {
      // sign user in

      try {
        const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
        // fetch user profile and set in state
        dispatch('fetchUserProfile', user)
        // redirect to Dashboard view on success
        router.push('/')

      } catch (error) {
        // console.error('opps!', error);
        // redirect to login view
        alert('Opps! ' + error.message + ' Please try again.');
        router.push('/login')
      }

    },
    async signup({ dispatch }, form) {
      // sign user up

      try {

        const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

        // create user object in userCollections
        await fb.usersCollection.doc(user.uid).set({
          name: form.name,
          title: form.title,
          email: form.email
        })

        // fetch user profile and set in state
        dispatch('fetchUserProfile', user)

        // redirect to Dashboard view on success
        router.push('/')

      } catch (error) {
        // console.error('opps!', error);
        // redirect to login view
        alert('Opps! ' + error.message + ' Please try again.');
        router.push('/login')
      }


    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(user.uid).get()

      // set user profile in state
      commit('setUserProfile', userProfile.data())
      const fbUserId = fb.auth.currentUser.uid
      commit('setFbUID', fbUserId )

      // change route to dashboard
      if (router.currentRoute.path === '/login') {
        router.push('/')
      }
    },
    async loadPosts({ commit }) {

      fb.postsCollection.orderBy('createdOn', 'desc').onSnapshot(snapshot => {
        let postsArray = []

        snapshot.forEach(doc => {
          let post = doc.data()
          post.id = doc.id

          postsArray.push(post)
        })

        commit('setPosts', postsArray)
      })

    },
    async logout({ commit }) {
      // log user out
      await fb.auth.signOut()

      // clear user data from state
      commit('setUserProfile', {})

      // redirect to login view
      router.push('/login')
    },
    async createPost({ state, commit }, post) {

      // console.log ('state.userProfile.name', state.userProfile)
      // create post in firebase
      await fb.postsCollection.add({
        createdOn: new Date(),
        content: post.content,
        priority: post.priority,
        warningTimout: post.warningTimout,
        scope: post.scope,
        countdown: post.countdown,
        userId: fb.auth.currentUser.uid,
        userName: state.userProfile.name,
        userEmail: state.userProfile.email,
        comments: 0,
        likes: 0
      })
    },
    async likePost({ commit }, post) {
      const userId = fb.auth.currentUser.uid
      const docId = `${userId}_${post.id}`

      // check if user has liked post
      const doc = await fb.likesCollection.doc(docId).get()
      if (doc.exists) { return }

      // create post
      await fb.likesCollection.doc(docId).set({
        postId: post.id,
        userId: userId
      })

      // update post likes count
      fb.postsCollection.doc(post.id).update({
        likes: post.likesCount + 1
      })
    },
    async updateProfile({ state, dispatch }, user) {
      const userId = fb.auth.currentUser.uid
      // update user object
      const userRef = await fb.usersCollection.doc(userId).update({
        name: 'user.name on update',
        title: user.title
      })

      dispatch('fetchUserProfile', { uid: userId })

      // console.log ('state.userProfile.name', state.userProfile)
      // userEmail: state.userProfile.email,
      // update all posts by user
      const postDocs = await fb.postsCollection.where('userId', '==', userId).get()
      postDocs.forEach(doc => {
        fb.postsCollection.doc(doc.id).update({
          userName: user.name
        })
      })

      // update all comments by user
      const commentDocs = await fb.commentsCollection.where('userId', '==', userId).get()
      commentDocs.forEach(doc => {
        fb.commentsCollection.doc(doc.id).update({
          userName: user.name
        })
      })
    }
  }
})