import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from '@/config/firebase'
import '@/registerServiceWorker'


let app = null

auth.onAuthStateChanged( user => {
  if (!app) {
    app = createApp(App)
    app.use(store)
    app.use(router)
    app.mount('#app')

    if (user) {
      store.dispatch('fetchUserProfile', user)
    }
  }
})