<template>
<div id="nav">
  <h1>Virtual Reminders</h1>
  <p>For Incident Managerment</p>
  <router-link to="/">Dashboard</router-link> |
  <router-link to="/add-reminder">Add Reminder</router-link> |
  <router-link to="/settings">Settings</router-link> |
  <router-link to="/about">About</router-link>
</div>
<router-view />
<footer class="footer-container">
  <div class="footer-container--nav">
    <a @click.prevent="logout()">Logout</a> |
    <a v-if="hasMessaging" @click="getMessagingToken">Get notifications</a>
    <span v-else><small>Push notify not supported</small></span>
  </div>

  &copy; 2020 F.C &amp; Company. All rights reserved.
</footer>
</template>

<script>
import {
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
  onErrorCaptured,
} from "vue";

import {
  messaging
} from "@/config/firebase";
import axios from "axios";

export default {
  name: "App",
  data: () => ({
    hasMessaging: false
  }),
  mounted() {
    this.listenTokenRefresh();

    if (messaging) {
      this.hasMessaging = true;
    }

  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    getMessagingToken() {
      if (messaging) {
        messaging
          .getToken()
          .then(async (token) => {
            if (token) {
              const currentMessageToken = window.localStorage.getItem(
                "messagingToken"
              );
              // console.log(
              //   "token will be updated",
              //   currentMessageToken != token
              // );

              if (currentMessageToken != token) {
                await this.saveToken(token);
              }
            } else {
              console.error(
                "No Instance ID token available. Request permission to generate one."
              );
              this.notificationsPermisionRequest();
            }
          })
          .catch(function (err) {
            console.error("An error occurred while retrieving token. ", err);
          });
      } else {
        console.info("App: Push Notify disabled by browser.");
      }
    },
    notificationsPermisionRequest() {
      if (messaging) {
        messaging
          .requestPermission()
          .then(() => {
            this.getMessagingToken();
          })
          .catch((err) => {
            console.error("Unable to get permission to notify.", err);
          });
      } else {
        console.info(
          "App request permissions: Push Notify disabled by browser."
        );
      }
    },
    listenTokenRefresh() {
      const currentMessageToken = window.localStorage.getItem("messagingToken");
      // console.log("currentMessageToken", currentMessageToken);

      if (!!currentMessageToken) {

        if (messaging) {

          this.hasMessaging = true;
          // Handle incoming messages. Called when:
          // - a message is received while the app has focus
          // - the user clicks on an app notification created by a service worker
          //   `messaging.setBackgroundMessageHandler` handler.
          messaging.onMessage((payload) => {
            //  console.log('Message received. ', payload);
            // ...
          });

          messaging.onTokenRefresh(function () {
            messaging
              .getToken()
              .then(function (token) {
                // console.log(
                //   "got token .... yes .... currentMessageToken",
                //   currentMessageToken
                // );
                this.saveToken(token);
              })
              .catch(function (err) {
                console.error("Unable to retrieve refreshed token ", err);
              });
          });
        } else {
          console.info("App listen: Push Notify disabled by browser.");
        }
      }
    },
    saveToken(token) {
      // console.log("tokens", token);
      if (messaging) {
        axios
          .post(
            `https://us-central1-virtual-reminders.cloudfunctions.net/GeneralSubscription`, {
              token,
            }
          )
          .then((response) => {
            window.localStorage.setItem("messagingToken", token);
            // console.log(response);
          })
          .catch((err) => {
            console.log('app: error', err);
          });
      } else {
        console.info('App: save token disabled on push')
      }
    },
  },
  setup() {
    onBeforeMount(() => {
      // console.log('onBeforeMount via setup');
      //  this.$store.dispatch('autoSignIn', user)
    });
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

// #nav {
//   padding: 30px;
// }

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.footer-container {
  border-top: 1px solid gainsboro;
  padding-top: 1rem;

  &--nav {
    margin-bottom: 1rem;
  }
}
</style>
