import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/messaging'
import 'firebase/storage'
// import "firebase/analytics"

// import '@/registerServiceWorker'

// firebase init
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASURMENT
}

firebase.initializeApp(firebaseConfig)

const storage = firebase.storage()

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
} else {
  console.info ('config firebase : Push Notifications are not supported by the browser.')
}


const db = firebase.firestore()
      db.enablePersistence({synchronizeTabs:true})

const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
const postsCollection = db.collection('posts')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')

// export utils/refs
export {
  db,
  auth,
  storage,
  messaging,
  usersCollection,
  postsCollection,
  commentsCollection,
  likesCollection
}
