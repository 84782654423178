<template>
<p class="reminder-container">
  <strong> Reminder: {{ formatDate(countDownDate) }} </strong>
</p>
<div class="countdown-container" v-if="timesupMsg != 'EXPIRED!'">
  <strong>Expires: </strong> {{ displayHours }}
  <strong>hrs</strong> : {{ displayMinutes }} <strong>min</strong>:
  {{ displaySeconds }} <strong>sec</strong>
</div>
<div v-else style="color: #ff0000;">
  <strong>{{ timesupMsg }}</strong>
</div>
<div v-if="timesupMsg === 'WARNING!'" style="color: #FFA500; padding-top: .3rem;">
  <strong>{{ timesupMsg }}</strong>
</div>
</template>

<script>
import {
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
  onErrorCaptured,
} from "vue";

import moment from "moment";
import axios from "axios";
import {
  messaging
} from "@/config/firebase";

export default {
  name: "Counter",
  emits: ["showWarning", "showExpired", "show-warning", "show-expired"],
  props: [
    "year",
    "month",
    "date",
    "hour",
    "minute",
    "second",
    "millisecond",
    "warningTimout",
    "authorEmail",
    "authorAuthId",
    "authorName"
  ],
  setup() {
    onMounted(() => {
      // console.log('onMounted via setup')
    });
  },
  data: () => ({
    displayMinutes: 0,
    displaySeconds: 0,
    displayHours: 0,
    displayDays: 0,
    countDownDate: 0,
    timesupMsg: "",
    soundWarningCount: 0,
    soundExpireCount: 0
  }),
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 24;
    },
    end() {
      return new Date(
        this.year,
        this.month,
        this.date,
        this.hour,
        this.minute,
        this.second,
        this.millisecond
      );
    },
  },
  mounted() {
    this.showRemaining();
  },
  methods: {
    sendPushNotify(msg) {

      // console.log('doc authorAuthID ' + this.authorAuthId);
      // console.log('!!!!!!!!!!!!!!!!!!!! doc fbUID ' + this.$store.getters.fbUID);
      const fbUIDCheck = this.$store.getters.fbUID;

      if (this.authorAuthId !== fbUIDCheck) {
        console.info('not sending push as not the owner of the post')
        return;
      }

      const sendMsg = "Author: " + this.authorName
      // console.log("sendPushNotify", msg);
      let URL = null
      if (msg === "WARNING!") {
        URL = "https://us-central1-virtual-reminders.cloudfunctions.net/SendPushMessage"
      } else {
        URL = "https://us-central1-virtual-reminders.cloudfunctions.net/SendPushMessageExpire"
      }

      if (messaging) {
        axios
          .post(
            `https://us-central1-virtual-reminders.cloudfunctions.net/SendPushMessage`, {

              msg: msg,
              author: sendMsg

            }
          )
          .then((response) => {
            // console.log("counter got response ", response);
          })
          .catch((err) => {
            console.error('counter got error: ', err);
          });
      } else {
        console.info('App: push disabled on warning')
      }
    },
    playSound(sound, count, msg) {

      if (count < 1) {
        // const audio = new Audio(sound);
        // audio.play();
        // console.log('push message msg', msg)
        this.sendPushNotify(msg)
        //  alert('virtual reminder notice: ' + msg);

      }
    },
    formatDate(date) {
      return moment(date).format("llll");
    },
    formatNum: (num) => (num < 10 ? "0" + num : num),
    showRemaining() {
      // Set the date we're counting down to
      this.countDownDate = this.end;

      // Update the count down every 1 second
      const timer = setInterval(() => {
        // Get today's date and time
        const now = new Date();

        // Find the distance between now and the count down date
        const distance = this.countDownDate.getTime() - now.getTime();

        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        // console.log(
        //   days + "d " + hours + "h " + minutes + "m " + seconds + "s "
        // );

        this.displayMinutes = this.formatNum(minutes);
        this.displaySeconds = this.formatNum(seconds);
        this.displayHours = this.formatNum(hours);
        this.displayDays = this.formatNum(days);

        // console.log(" this.displayMinutes ", minutes);
        //   console.log(" this.warningTimout ", this.warningTimout);

        if (this.displayMinutes <= this.warningTimout) {
          // console.log("show warning...", this.soundWarningCount);
          this.timesupMsg = "WARNING!";
          this.$emit("show-warning", true);
          this.playSound('', this.soundWarningCount, "WARNING!");
          this.soundWarningCount++;
        }

        // If the count down is over, write some text
        if (distance < 0) {

          this.timesupMsg = "EXPIRED!";
          this.$emit("show-expired", true);
          this.playSound('', this.soundExpireCount, "EXPIRED!");
          this.soundExpireCount++;
          // console.log("time's up", this.soundExpireCount);
          clearInterval(timer);

          return;

        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.reminder-container {
  font-size: 1rem;
}

.countdown-container {
  font-size: 1.2rem;
}
</style>
